@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-multi-email/dist/style.css';
.header-section.shadowBottom {
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
}

.container {
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
}
.result.w-\[1366px\] {
    width: 1200px;
}
.result.w-\[991px\] {
    width: 991px;
}
.result.w-\[767px\] {
    width: 767px;
}
.result.w-\[576px\] {
    width: 576px;
}

textarea::-webkit-scrollbar {
    width: 5px;
}
textarea::-webkit-scrollbar-track {
    background: #f1f1f1;
}
textarea::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}
textarea::-webkit-scrollbar-thumb:hover {
    background: #555;
}
iframe::-webkit-scrollbar {
    width: 5px;
}
iframe::-webkit-scrollbar-track {
    background: #f1f1f1;
}
iframe::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}
iframe::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.laptop-device.active,
.tablet-device.active,
.mobile-device.active {
    background-color: #fbf2fb;
}

/* outline-none border border-primary-color/20 rounded-lg p-[10px] pl-4 font-normal text-sm text-primary-color placeholder:text-primary-color/30 focus:outline-none */
.test-email .react-multi-email {
    padding: 10px 10px 10px 16px !important;
}
.test-email .react-multi-email.focused {
    border-color: rgb(19 15 64 / 0.2) !important;
}
.test-email .react-multi-email > span[data-placeholder] {
    color: rgb(19 15 64 / 0.3) !important;
    font-size: 14px;
    line-height: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.test-email .react-multi-email > input {
    padding: unset !important;
    font-size: 14px;
    line-height: 20px;
    color: rgb(19 15 64);
    font-weight: 400;
}
.react-multi-email [data-tag] {
    padding: 5px 5px;
}
